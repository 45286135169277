
import { Vue, Component, Prop } from "vue-property-decorator";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  ChartTitle,
  PieChartEntry,
  PieChartEntryValue,
} from "@/models/echart/types";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

@Component({
  components: {
    VChart,
  },
})
export default class EChartPie extends Vue {
  @Prop() readonly title!: string;
  @Prop({ default: "left" }) readonly titleAlignment!: string;
  @Prop({ default: (): any => [] }) readonly palette!: string[];
  @Prop({ default: (): any => [] }) readonly values!: PieChartEntry;
  @Prop({ default: true }) readonly showLegend!: boolean;
  @Prop({ default: "left" }) readonly legendPosition!: string;
  @Prop({ default: "vertical" }) readonly legendOrientation!: string;

  get titleConfig(): any {
    return { text: this.title, left: this.titleAlignment };
  }

  get labels() {
    return this.values?.map((o: PieChartEntryValue) => o.name);
  }

  get legend() {
    return this.showLegend
      ? {
          orient: this.legendOrientation,
          textStyle: { fontFamily: "Titillium Web" },
          left: this.legendPosition,
          data: this.labels,
        }
      : null;
  }

  get option() {
    return {
      title: this.titleConfig,
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} ({d}%)",
        textStyle: { fontFamily: "Titillium Web" },
      },
      legend: this.legend,
      series: [
        {
          color: this.palette || [],
          name: this.title,
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: this.values,
          label: {
            textStyle: {
              fontFamily: "Titillium Web",
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  }
}
