
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/Common/VSelect.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { httpStore } from "@/store/typed";
import Throttler from "@/utils/Throttler";
import { IndicatorTaxonomy, Initiative } from "@/models";
import IndicatorMixin, {
  IndicatorMixinTags,
} from "@/mixins/http/IndicatorMixin";
import { INDICATOR_DESCRIPTIONS } from "@/utils/Constants";

@Component({ components: { VSelect } })
export default class IndicatorsSelect extends Mixins(IndicatorMixin) {
  @RegisterHttp(IndicatorMixinTags.IndicatorTaxonomy)
  readonly getRequest!: RequestData;

  @Prop({ required: false })
  readonly selected!: any | null;

  @Prop()
  readonly queryKey?: string;

  @Prop()
  readonly label?: string;

  @Prop({ default: "Seleziona un indicatore" })
  readonly placeholder?: string;

  @Prop({ default: false })
  readonly preselect!: boolean;

  @Prop({ default: false })
  readonly actsAsFilter!: boolean;

  @Prop()
  readonly filter!: any;

  options: VSelectOption[] = [];

  private value: any | null = this.selected || null;

  private items: string[] = [];
  private term = "";
  private throttler: Throttler = new Throttler();

  mounted() {
    this.loadItems();
  }

  onSearched(term: string) {
    this.throttler.run(() => {
      this.term = term;
      this.loadItems();
    });
  }

  @Watch("selected")
  onSelectedChanged(selected: string) {
    this.value = selected;
    this.onItemsReady();
  }

  get payload() {
    let data: any = {
      page: {
        size: 100,
      },
    };

    if (this.filter) {
      data.filter = this.filter;
      data.filter.term = this.term;
    } else {
      data.filter = { term: this.term };
    }

    return data;
  }

  get filterDefault() {
    return { id: "all", text: "Tutto" };
  }

  get loading() {
    return httpStore.status[this.tag]?.loading;
  }

  get tag() {
    return IndicatorMixinTags.IndicatorTaxonomy;
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);
    this.$emit(
      "inputRaw",
      this.items.find((o: string) => o === id)
    );

    if (this.queryKey && this.$route.query[this.queryKey] !== this.value) {
      let query = this.$route.query;
      query[this.queryKey] = this.value;

      this.$router.push({ query });
    }
  }

  onItemsReady() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find((o) => o.id === this.selected)?.id;
    }
  }

  async loadItems() {
    const [data, error] = await safeAsync(this.getTaxonomy(this.payload));

    if (data) {
      this.items = data.options;
      this.options = this.items.map((o: string) => {
        let fullCode = "";

        if (this.payload.filter?.category == "IG") {
          const code =
            this.payload.filter.category == "IG"
              ? o.replace(/[^0-9]+/gi, "")
              : o;
          fullCode = `${this.payload.filter.category}-${code}`;
        } else if (this.payload.filter?.category == "IS") {
          fullCode = o;
        }

        const indShortDes = INDICATOR_DESCRIPTIONS[fullCode]?.long;
        return indShortDes
          ? { id: o, text: `${o} - ${indShortDes}` }
          : { id: o, text: o };
      });

      if (this.actsAsFilter) {
        this.options.unshift(this.filterDefault);
      }

      this.onItemsReady();
    } else {
      console.log(error);
    }
  }
}
