
import VBaseMultiSelect from "./VBaseMultiSelect.vue";
import VCheckbox from "./VCheckbox.vue";
import ClickOutside from "@/directives/ClickOutside";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

export interface MultiSelectOption {
  id?: string;
  name: string;
  group?: string;
}

export interface MultiSelectGroup {
  id: string;
  name: string;
}

export interface MultiSelectAction {
  active: boolean;
  filter?: () => boolean;
}

@Component({
  components: { VBaseMultiSelect, VCheckbox },
  directives: { ClickOutside },
})
export default class MultiSelect extends Vue {
  @Prop({
    default: (): any => {
      return [];
    },
  })
  readonly value!: any[];
  @Prop({ required: true }) readonly options!: MultiSelectOption[];
  @Prop() readonly actions!: MultiSelectAction[] | null;
  @Prop() readonly groups!: MultiSelectGroup[] | null;
  @Prop() groupBy!: (
    options: MultiSelectOption[],
    group: MultiSelectGroup
  ) => MultiSelectOption[] | null;
  @Prop({ default: "Seleziona un valore" }) readonly placeholder!: string;
  @Prop({ default: "name" }) readonly label!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: false }) readonly confirmSelection!: boolean;
  @Prop({ default: false }) readonly showDropdown!: boolean;
  @Prop({ default: false }) readonly searchEnabled!: boolean;

  private selected = this.value;
  private maxElements = 50;
  private offset = 0;
  private search: string | null = null;
  private dropdownVisible = false;

  @Watch("value")
  onValueChanged(value: any[]) {
    this.selected = value;
  }

  @Watch("disabled")
  onDisabledChanged(value: boolean) {
    if (value) {
      this.selected = [];
    }
  }

  onOutsideClick() {
    this.dropdownVisible = false;
  }

  @Watch("selected")
  onSelectedChanged(value: any[]) {
    if (!this.confirmSelection) {
      this.$emit("input", value);
    }
  }

  get isGrouped() {
    return this.groups && this.groups.length;
  }

  get groupedOptions() {
    if (!this.groups) {
      return [];
    }
    return this.groups
      .map((group) => {
        return { ...group, options: this.getGroupedOptions(group) };
      })
      .filter((group) => {
        return (group.options?.length || 0) > 0;
      });
  }

  get filteredOptions() {
    if (this.search) {
      return this.options.filter((option) => {
        return (
          option.name.toLowerCase().indexOf(this.search?.toLowerCase() || "") >
          -1
        );
      });
    } else {
      return this.options;
    }
  }

  getActionCssClass(action: MultiSelectAction) {
    return {
      "list-inline-item": true,
      active: action.active,
    };
  }

  getGroupedOptions(group: MultiSelectGroup) {
    if (this.groupBy instanceof Function) {
      return this.groupBy(this.options, group);
    } else {
      return this.options.filter((element) => {
        let valid = element.group === group.id;

        if (this.search) {
          valid =
            valid &&
            element.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        }

        return valid;
      });
    }
  }

  onSelectAll() {
    this.selected = [];

    for (const option of this.filteredOptions) {
      this.selected.push(option);
    }

    if (this.actions) {
      this.actions.map((element) => {
        return (element.active = true);
      });
    }
  }

  onSelectGroup(group: { id: string }) {
    const filtered = this.filteredOptions.filter(
      (element) => element.group === group.id
    );
    const selectedSize = this.selected?.length || 0;
    this.selected = [...new Set([...this.selected, ...filtered])];
    if (selectedSize === this.selected.length) {
      this.selected = this.selected.filter((x) => !filtered.includes(x));
    }
  }

  onReset() {
    if (this.actions) {
      this.actions.map((element) => {
        return (element.active = false);
      });
    }
  }

  onApply() {
    this.dropdownVisible = false;
    this.$emit("input", this.selected);
  }

  onCleared() {
    if (this.confirmSelection) {
      this.$emit("input", this.selected);
    }
  }

  onScroll(e: Event, holder: HTMLElement | null) {
    if (!holder) {
      return;
    }
    if (!holder.getBoundingClientRect) {
      return;
    }
    const upperSensors = this.$refs.upperSensor as Element[];
    const lowerSensors = this.$refs.lowerSensor as Element[];

    if (upperSensors) {
      const holderRect = holder.getBoundingClientRect();

      for (let i = 0; i < upperSensors.length; i++) {
        const sensor = upperSensors[i];
        const { top, bottom, height } = sensor.getBoundingClientRect();
        if (holderRect.top - top < 0) {
          this.offset = parseInt(sensor.getAttribute("data-index") || "0");
          break;
        }
      }
    }

    if (lowerSensors) {
      const holderRect = holder.getBoundingClientRect();

      for (let i = lowerSensors.length - 1; i >= 0; i--) {
        const sensor = lowerSensors[i];
        const { top, bottom, height } = sensor.getBoundingClientRect();
        if (holderRect.bottom - top > 0) {
          this.offset = parseInt(sensor.getAttribute("data-index") || "0");
          break;
        }
      }
    }
  }

  onAction(action: MultiSelectAction) {
    if (action.filter && action.filter instanceof Function) {
      const filtered = this.filteredOptions.filter(action.filter);

      if (action.active) {
        this.selected = this.selected.filter(
          (element) => filtered.indexOf(element) === -1
        );
        action.active = false;
      } else {
        this.selected = [...new Set([...this.selected, ...filtered])];
        action.active = true;
      }
    }
  }

  onSearch(term: string) {
    this.search = term;
  }

  onToggleVisible(visible: boolean) {
    if (visible) {
      this.offset = 0;
    }

    this.dropdownVisible = visible;
  }
}
