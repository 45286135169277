
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import EstimateRequestsList from "@/components/EstimateRequest/List.vue";
import PageSection from "@/components/Common/PageSection.vue";

@Component({
  components: {
    EstimateRequestsList,
    PageSection,
  },
})
export default class ProjectManagementEstimateRequests extends Vue {}
