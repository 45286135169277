
import { Component, Mixins, Prop } from "vue-property-decorator";
import IndicatorMixin from "@/mixins/http/IndicatorMixin";
import VSelect, { VSelectOption } from "@/components/Common/VSelect.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import { IndicatorFilterOptions, IndicatorFilter } from "@/models/echart/types";
import { Initiative, Organization } from "@/models";
import { watcherStore, monitoringStore } from "@/store/typed";
@Component({
  components: {
    VSelect,
  },
})
export default class IndicatorFilters extends Mixins(IndicatorMixin) {
  private filters: IndicatorFilterOptions | null = null;
  private selectedFilter: IndicatorFilter = {};
  @Prop({ default: "watcher" }) readonly store: "monitoring" | "watcher";

  async loadFilters() {
    const [data, errors] = await safeAsync(this.getFilters());

    if (data) {
      this.filters = data;
    } else {
      console.error(errors);
    }
  }
  get emptyFilter() {
    return !Object.keys(monitoringStore.filter).length;
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get regions() {
    const options =
      this.filters.organizationRegions?.map((o: any) => {
        return { id: o, text: o };
      }) || [];

    options.unshift(this.all);
    return options;
  }

  get categories() {
    const options =
      this.filters.organizationCategories?.map((o: any) => {
        return { id: o, text: o };
      }) || [];
    options.unshift(this.all);
    return options;
  }

  get all() {
    return { id: "all", text: "Tutte" };
  }

  get kinds() {
    const options =
      this.filters.organizationTypes?.map((o: any) => {
        return {
          id: o,
          text: Organization.kinds.find((k: any) => k.id === o)?.label,
        };
      }) || [];

    options.unshift(this.all);
    return options;
  }

  get steps() {
    const options =
      this.filters.initiativeSteps?.map((o: any) => {
        return {
          id: o,
          text: Initiative.steps.find((s: any) => s.id === o)?.label,
        };
      }) || [];

    options.unshift(this.all);
    return options;
  }

  onRegionSelected(region: string) {
    this.selectedFilter.organizationRegion = region;
    this.emitChange();
  }

  onCategorySelected(category: string) {
    this.selectedFilter.organizationCategory = category;
    this.emitChange();
  }

  onStepSelected(step: string) {
    this.selectedFilter.initiativeStep = step;
    this.emitChange();
  }

  onKindSelected(kind: string) {
    this.selectedFilter.organizationType = kind;
    this.emitChange();
  }

  emitChange() {
    Object.keys(this.selectedFilter).forEach((key: string) => {
      if ((this.selectedFilter as any)[key] == "all") {
        delete (this.selectedFilter as any)[key];
      }
    });
    switch (this.store) {
      case "monitoring":
        monitoringStore.setFilter(this.selectedFilter);
        break;
      case "watcher":
        watcherStore.setFilter(this.selectedFilter);
        break;
    }
    this.$emit("changed", this.selectedFilter);
  }

  mounted() {
    this.loadFilters();
  }
}
