
import NumberFormatter from "@/utils/NumberFormatter";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class BarProgress extends Vue {
  @Prop({ required: true }) readonly value!: number;
  @Prop({ required: true }) readonly progress!: number;
  @Prop({ required: true }) readonly text!: string;

  get style() {
    return { width: `${this.progress}%` };
  }

  get parsedValue() {
    return NumberFormatter.engToItalian(this.value);
  }
}
