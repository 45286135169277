
import * as echarts from "echarts";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { MapChartEntry, MapChartEntryValue } from "@/models/echart/types";
import { currentUserStore } from "../../store/typed";

@Component({
  components: {},
})
export default class EChartMap extends Vue {
  private chart: any = null;

  @Prop({ default: (): any => [] }) readonly palette!: string[];
  @Prop({ default: (): any => [] }) readonly values!: MapChartEntry;
  @Prop() readonly mapName!: string;

  svg = require(`!svg-inline-loader!@/assets/chart/${this.mapName}.svg`);

  get currentUser() {
    return currentUserStore.currentUser;
  }

  get min() {
    return (
      Math.min(
        ...this.values.map((o: MapChartEntryValue) =>
          parseFloat(o.value.toString())
        )
      ) + 100
    );
  }

  get max() {
    return (
      Math.max(
        ...this.values.map((o: MapChartEntryValue) =>
          parseFloat(o.value.toString())
        )
      ) - 100
    );
  }

  init() {
    this.chart = echarts.init(this.mapChartContainer);

    echarts.registerMap("map", { svg: this.svg });
    this.chart.setOption(this.option);
    this.chart.on("click", { seriesIndex: 0 }, (event: any) => {
      // if(event.data) {
      this.$emit("select", event.data);
      // }
    });
  }

  async mounted() {
    this.init();
  }

  @Ref() mapChartContainer: any;

  get option() {
    return {
      tooltip: {
        filter: function (_tooltipItem: any, data: any) {
          console.log(data);
          return Number.isNaN(data.value);
        },
        trigger: "item",
        borderColor: "white",
        fontFamily: "Titillium Web",
        formatter: function (params: any) {
          return `<b>${params.name}</b>
          <p class="mb-0"> ${
            Number.isNaN(params.value)
              ? "Nessun indicatore"
              : params.value + "%"
          }</p>`;
        },
      },
      visualMap: {
        show: false,
        left: "center",
        bottom: "10%",
        min: this.min || 0,
        max: this.max || 100,
        orient: "horizontal",
        text: ["Name"],
        realtime: false,
        calculable: false,
        inRange: {
          color: this.palette,
        },
      },

      grid: {
        left: "60%",
        top: "20%",
        bottom: "20%",
      },
      series: [
        {
          itemStyle: {
            areaColor: "#fafafa",
            borderColor: "#cecece",
            borderWidth: 1.5,
            fontFamily: "Titillium Web",
          },
          selectMode: "single",
          select: {
            itemStyle: {
              color: this.palette[Math.ceil(this.palette.length / 2)],
              borderColor: this.palette[Math.ceil(this.palette.length / 2)],
              borderWidth: 1.5,
            },
            label: {
              show: false,
              textBorderColor: "#fff",
              textBorderWidth: 2,
            },
          },

          name: "",
          type: "map",
          map: "map",
          emphasis: {
            itemStyle: {
              areaColor: this.palette[Math.ceil(this.palette.length / 2)],
            },
            label: {
              show: false,
            },
          },
          data: this.values,
        },
      ],
    };
  }
}
