
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import GaugeChart from "@/components/EChart/Gauge.vue";
import CollaborationIndicatorReport from "@/models/CollaborationIndicatorReport";
import { CHART_PRIMARY_COLOR, INDICATOR_DESCRIPTIONS } from "@/utils/Constants";
import DescriptionModal from "@/components/Common/DescriptionModal.vue";

@Component({
  components: {
    GaugeChart,
    DescriptionModal,
  },
})
export default class CollaborationIndicatorItem extends Vue {
  @Prop({ required: true }) readonly report!: CollaborationIndicatorReport;

  @Ref() readonly descriptionModal!: DescriptionModal;

  get palette() {
    return [CHART_PRIMARY_COLOR];
  }

  get value() {
    return this.report.data.toFixed(2);
  }

  get key() {
    return `${this.report.category}-${this.report.code}`;
  }

  get label() {
    return INDICATOR_DESCRIPTIONS[this.key]?.long;
  }

  private openDescriptionModal() {
    const title = this.key;
    const description = this.label;
    this.descriptionModal.show(description, title);
  }
}
