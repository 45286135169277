
import { Component, Vue } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import StickyMenu, { StickyMenuLink } from "@/components/Common/StickyMenu.vue";
import Events from "@/components/WatcherArea/Events.vue";
import Documents from "@/components/WatcherArea/Documents.vue";
import GeneralIndicators from "@/components/WatcherArea/GeneralIndicators.vue";
import { currentUserStore } from "@/store/typed";
import IndicatorDistribution from "@/components/Indicators/Distribution/Section.vue";
import { IndicatorFilter } from "@/models/echart/types";
import IndicatorFilters from "@/components/Indicators/Filters.vue";
import DigitalSpecificIndicators from "@/components/WatcherArea/DigitalSpecificIndicators.vue";
import DigitalSpecificIndicator from "@/models/DigitalSpecificIndicator";
import ExportData from "@/components/Indicators/ExportData.vue";
import { printStore } from "../store/typed";

@Component({
  components: {
    PageSection,
    StickyMenu,
    Events,
    GeneralIndicators,
    IndicatorDistribution,
    Documents,
    IndicatorFilters,
    DigitalSpecificIndicators,
    ExportData,
  },
})
export default class WatchersArea extends Vue {
  private filter: IndicatorFilter = {};

  private showFilters = false;

  get printing() {
    return printStore.printing;
  }

  private links: StickyMenuLink[] = [
    {
      section: "#watcher-area-indicators-distribution",
      icon: "it-presentation",
      name: "Distribuzione indicatori",
    },
    {
      section: "#watcher-area-general-indicators",
      icon: "it-inbox",
      name: "Indicatori generali",
    },
    {
      section: "#watcher-area-specific-indicators",
      icon: "it-user",
      name: "Indicatori specifici",
    },
    {
      section: "#watcher-area-documents",
      icon: "it-bookmark",
      name: "Pubblicazioni e documenti",
    },

    { section: "#watcher-area-events", icon: "it-calendar", name: "Eventi" },
  ];

  private digitalIndicators: DigitalSpecificIndicator[] = [];

  created() {
    for (let i = 0; i < 33; i++) {
      this.digitalIndicators.push(DigitalSpecificIndicator.dummy());
    }
  }

  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }

  get isAdmin() {
    return currentUserStore.user?.isApplicationAdmin;
  }

  onFiltersChanged(filter: IndicatorFilter) {
    this.filter = filter;
  }
}
