
import { informativeStore } from "@/store/typed";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast } from "@/utils/Toast";
import { Vue, Component } from "vue-property-decorator";
import VSvg from "@/components/Common/VSvg.vue";
import FileDownloader from "@/components/Common/FileDownloader.vue";
import PageSection from "@/components/Common/PageSection.vue";

@Component({
  components: {
    VSvg,
    PageSection,
    FileDownloader,
  },
})
export default class WatcherAreaDocuments extends Vue {
  created() {
    this.loadDocuments();
  }

  async loadDocuments() {
    const [data, errors] = await safeAsync(informativeStore.loadContent());

    if (errors) {
      showErrorToast("Sì è verificato un errore nel caricare i documenti");
      return;
    }
  }

  get content() {
    return informativeStore.content;
  }

  get reportDocs() {
    return this.content?.report_docs;
  }

  get surveyDocs() {
    return this.content?.survey_docs;
  }

  get otherMaterialsDocs() {
    return this.content?.other_materials_docs;
  }
}
