
import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import { Contract } from "@/models";
import DigitalPath from "@/components/Milestone/DigitalPath.vue";
import { currentUserStore, httpStore } from "../../store/typed";
import MilestoneModal from "@/components/Milestone/ReadonlyModal.vue";
import PageSection from "@/components/Common/PageSection.vue";
import InitiativeMixin, { InitiativeTags } from "@/mixins/http/InitiativeMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { Initiative, Milestone } from "../../models";

@Component({
  components: { MilestoneModal, DigitalPath, PageSection },
})
export default class InitiativesWithMilestones extends Mixins(InitiativeMixin) {
  @Prop({ required: true }) readonly title!: string[];
  @Prop({ required: true }) readonly icon!: string[];
  private initiatives: Milestone[] = [];
  @Ref() readonly showModal!: any;

  created() {
    this.load();
  }

  showMilestone(milestone: Milestone, initiative: Initiative) {
    (this.showModal as any).open(milestone, initiative);
  }

  get loading() {
    return httpStore.status[InitiativeTags.InitiativesIndex]?.loading;
  }

  get organizationId() {
    return currentUserStore.currentUser.organization.id;
  }

  get initiativesWithMilestones() {
    return this.initiatives.filter((o: any) => o.milestones.length);
  }

  private async load() {
    const filters = {
      page: {
        size: "all",
      },
      include: "milestones",
      organization: this.organizationId,
    };

    const [data, errors] = await safeAsync(this.getInitiatives("all", filters));
    if (data) {
      this.initiatives = data;
    }
  }
}
