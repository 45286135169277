
import { Component, Mixins } from "vue-property-decorator";
import EventMixin, { EventMixinTags } from "@/mixins/http/admin/EventMixin";
import { Event } from "@/models";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast } from "@/utils/Toast";
import EventItem from "@/components/Event/Item.vue";
import { currentUserStore } from "@/store/typed";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import VSvg from "@/components/Common/VSvg.vue";

@Component({
  components: {
    EventItem,
    VSvg,
  },
})
export default class EventList extends Mixins(EventMixin) {
  private events: Event[] = [];
  private page = 1;

  @RegisterHttp(EventMixinTags.EventIndex)
  readonly eventsIndexRequest!: RequestData;

  get isMobile() {
    return this.$mq === "sm";
  }

  get loading() {
    return this.eventsIndexRequest?.loading;
  }

  async created() {
    await this.loadEvents(this.page);
  }

  private loadMore() {
    if (this.loading || this.eventsIndexRequest?.meta.nextPage === null) {
      return;
    }

    this.page++;
    this.loadEvents(this.page);
  }

  reload() {
    this.loadEvents();
  }

  private async loadEvents(page = 1) {
    const payload: any = {
      include: "",
      filter: {},
      page: page,
    };

    payload.include = "user";

    const [data, errors] = await safeAsync(this.getEvents(payload));

    if (errors) {
      showErrorToast("Sì è verificato un errore nel caricare gli eventi");
      return;
    }

    if (page === 1) {
      this.events = [...data];
    } else {
      this.events.push(...data);
    }
  }
}
