export function realmCodeFromOrigin(): string {
  if (window.location.origin.includes('ppa')) {
    switch(true) {
      case window.location.origin.includes('ppa-north'):
        return 'ppa-north';
      case window.location.origin.includes('ppa-center'):
        return 'ppa-center';
    }
  }

  switch(true) {
    case window.location.origin.includes('north'):
      return 'north';
    case window.location.origin.includes('center'):
      return 'center';
    case window.location.origin.includes('l1'):
      return 'north';
    case window.location.origin.includes('l2'):
      return 'center';
    case window.location.origin.includes('l3'):
      return 'ppa-north';
    case window.location.origin.includes('l4'):
      return 'ppa-center';
  }
}

export function mapNameFromRealm(): string {
  switch(true) {
    case window.location.origin.includes('north'):
      return 'north';
    case window.location.origin.includes('center'):
      return 'center_south';
    case window.location.origin.includes('l1'):
      return 'north';
    case window.location.origin.includes('l2'):
      return 'center_south';
    case window.location.origin.includes('l3'):
      return 'ppa-north';
    case window.location.origin.includes('l4'):
      return 'ppa-center_south';
  }
}

export function monitoringDashboardTitleFromRealm(): string {
  const location = window.location.origin;

  if (location.includes('ppa') || location.includes('l3') || location.includes('l4')) {
    return "La presente sezione contiene gli strumenti di governo della fornitura e le informazioni di monitoraggio sui contratti esecutivi attivati per i servizi applicativi nell'Area Tematica \"Piattaforme applicative, portali e APP\" della gara Sanità Digitale";
  } else {
    return "La presente sezione contiene gli strumenti di governo della fornitura e le informazioni di monitoraggio sui contratti esecutivi attivati per i servizi applicativi nell'Area Tematica \"CUP e Interoperabilità dati sanitari\" della gara Sanità Digitale";
  }
}
