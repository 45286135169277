
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class RegionSavingsBadge extends Vue {
  @Prop({ required: true }) readonly saving!: number;
  @Prop({ required: true }) readonly label!: string;
  @Prop({ default: "positive" }) readonly savingPositivity!:
    | "positive"
    | "negative";

  get badgeClass() {
    switch (this.savingPositivity) {
      case "positive":
        return this.saving > 0 ? "success" : "warning";
      case "negative":
        return this.saving < 0 ? "success" : "warning";
    }

    return "";
  }

  get absoluteSaving() {
    return Math.abs(this.saving).toFixed(2);
  }
}
