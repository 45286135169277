
import { Component, Mixins, Prop } from "vue-property-decorator";
import IndicatorMixin from "@/mixins/http/IndicatorMixin";
import { watcherStore, monitoringStore } from "@/store/typed";

import VSvg from "@/components/Common/VSvg.vue";

@Component({
  components: {
    VSvg,
  },
})
export default class IndicatorFilters extends Mixins(IndicatorMixin) {
  @Prop({ default: "watcher" }) readonly store: "monitoring" | "watcher";

  exportData() {
    switch (this.store) {
      case "monitoring":
        monitoringStore.exportData();
        break;
      case "watcher":
        watcherStore.exportData();
        break;
    }
  }

  exportXmlData() {
    switch (this.store) {
      case "monitoring":
        monitoringStore.exportXmlData();
        break;
      case "watcher":
        watcherStore.exportXmlData();
        break;
    }
  }
}
