
import { Vue, Component, Prop } from "vue-property-decorator";
import moment from "moment";
import VSvg from "@/components/Common/VSvg.vue";
import { Event } from "@/models";

@Component({
  components: {
    VSvg,
  },
})
export default class EventLink extends Vue {
  @Prop({ required: true }) readonly event!: Event;

  get eventLink() {
    return this.event.address;
  }

  get date() {
    return moment(this.event.scheduledAt).format("ll");
  }
}
