import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_ADMIN_API_URL } from "@/utils/Constants";
import { Event } from "@/models";
import qs from "qs";
import { EventPayload } from "@/models/Event";

export enum EventMixinTags {
  EventCreate = "EventCreate",
  EventDestroy = "EventDestroy",
  EventIndex = "EventIndex",
  EventAddImage = "EventAddImage",
  EventContactOwner = "EventContactOwner",
}

@Component
export default class EventMixin extends Vue {
  getEvents(data: any): Promise<Event[]> {
    const url = `${SD_BASE_ADMIN_API_URL}/events`;
    return httpStore.request({
      tag: EventMixinTags.EventIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Event[]>;
  }

  createEvent(data: EventPayload): Promise<Event> {
    const url = `${SD_BASE_ADMIN_API_URL}/events`;
    return httpStore.request({
      tag: EventMixinTags.EventCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Event>;
  }

  addMedia(id: string, data: any): Promise<any> {
    const url = `${SD_BASE_ADMIN_API_URL}/events/${id}/add_media`;
    return httpStore.request({
      tag: EventMixinTags.EventAddImage,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<any>;
  }

  destroyEvent(id: string | number) {
    const url = `${SD_BASE_ADMIN_API_URL}/events/${id}`;
    return httpStore.request({
      tag: EventMixinTags.EventDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<Event>;
  }

  contactOwner(eventId: string, message: string) {
    const url = `${SD_BASE_ADMIN_API_URL}/events/${eventId}/contact_owner`;

    const data = {
      message: message,
    };

    return httpStore.request({
      tag: EventMixinTags.EventContactOwner,
      url: url,
      data: data,
      method: "POST",
    }) as Promise<any>;
  }
}
