
import { Component, Vue, Prop, Ref, Mixins } from "vue-property-decorator";
import Event from "@/models/Event";
import ExpandableText from "@/components/Common/ExpandableText.vue";
import VSvg from "@/components/Common/VSvg.vue";
import { currentUserStore } from "@/store/typed";
import { safeAsync } from "@/utils/AsyncUtil";
import EventMixin from "@/mixins/http/admin/EventMixin";
import { showErrorToast, showToast } from "@/utils/Toast";
import FileDownloader from "@/components/Common/FileDownloader.vue";
import EventLink from "@/components/Event/Link.vue";
import EventContactModal from "@/components/Event/ContactModal.vue";
@Component({
  components: {
    ExpandableText,
    EventContactModal,
    VSvg,
    FileDownloader,
    EventLink,
  },
})
export default class EventItem extends Mixins(EventMixin) {
  @Prop({ required: true }) readonly value!: Event;
  @Ref() readonly eventContactModal!: EventItem;

  get isMobile() {
    return this.$mq === "sm";
  }

  get canDelete() {
    return currentUserStore.currentUser?.isAdmin;
  }

  filename(url: string) {
    const split = url.split("/");
    return unescape(split[split.length - 1]);
  }

  private async deleteEvent() {
    const [data, errors] = await safeAsync(this.destroyEvent(this.value.id));

    if (errors) {
      showErrorToast(
        "Sì è verificato un problema con la cancellazione del event."
      );
      return;
    } else {
      showToast("Evento eliminato");
    }

    this.$emit("delete");
  }

  private openContactModal() {
    (this.eventContactModal as any).show();
  }
}
