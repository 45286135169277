
import { Component, Vue } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import StickyMenu, { StickyMenuLink } from "@/components/Common/StickyMenu.vue";
import Initiatives from "@/components/ProjectManagement/Initiatives.vue";
import EstimateRequests from "@/components/ProjectManagement/EstimateRequests.vue";
import Milestones from "@/components/ProjectManagement/Milestones.vue";

import { currentUserStore } from "@/store/typed";
@Component({
  components: {
    PageSection,
    Milestones,
    EstimateRequests,
    StickyMenu,
    Initiatives,
  },
})
export default class ProjectManagement extends Vue {
  private links: StickyMenuLink[] = [
    { section: "#initiatives", icon: "it-files", name: "Iniziative" },
    {
      section: "#milestones",
      icon: "it-map-marker-plus",
      name: "Digital path",
    },
    {
      section: "#estimate-requests",
      icon: "it-inbox",
      name: "Richieste di personalizzazione",
    },
  ];

  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }

  get isAdmin() {
    return currentUserStore.user?.isApplicationAdmin;
  }
}
