
import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import InlineMultiSelect from "@/components/Common/VInlineMultiSelect.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import IndicatorMixin from "@/mixins/http/IndicatorMixin";
import { safeAsync } from "@/utils/AsyncUtil";

const tag = "get_indicators";

@Component({ components: { InlineMultiSelect } })
export default class IndicatorsMultiselect extends Mixins(IndicatorMixin) {
  @Prop() readonly idSelected!: string | number;
  @Prop({ default: (): any[] => [] }) readonly value!: any[] | null;
  @Prop() readonly params!: any;
  @Prop() readonly formClass: string;
  @Prop() readonly searchEnabled: boolean;
  @Prop() readonly classes: string;
  @Prop() readonly dataKeys?: Array<string>;
  @Prop() readonly placeholder?: string;
  @Prop({ default: false }) readonly actsLikeFilter?: boolean;
  @Prop({ required: false }) readonly queryKey: string;
  @Prop({ default: true }) readonly preselect?: boolean;
  @Prop() readonly type: string;

  private options: any = [];

  private items: any = [];
  private id: any = this.idSelected || null;

  private filter = { ...this.params };

  @RegisterHttp(tag) getItemsTag: RequestData;

  get queryParams() {
    return { ...this.params };
  }

  @Watch("params", { deep: true })
  onParamsChanged(value: any) {
    this.filter = { ...this.params };
    this.loadItems();
  }

  mounted() {
    this.loadItems();
  }

  onSearch(term: string) {
    this.filter.term = term;
    this.loadItems();
  }

  onDataUpdated(data: any) {
    this.$emit("change", data);
  }

  async loadItems() {
    const [data, errors] = await safeAsync(
      this.getTaxonomy({ filter: this.filter })
    );
    if (data) {
      this.options = data.options.map((o: string) => {
        return { id: o, name: o };
      });
      this.$emit("loaded", this.options);
    }
  }

  onItemsReady() {
    if (this.idSelected) {
      this.id = this.idSelected;
    } else {
      if (this.preselect) {
        this.id =
          this.queryKey && this.$route.query[this.queryKey]
            ? this.$route.query[this.queryKey]
            : this.options[0]?.id;
      }
    }
  }
}
