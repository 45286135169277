
import { Vue, Component } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import QualitativeIndicators from "@/components/Indicators/Qualitative/List.vue";
import CollaborationIndicators from "@/components/Indicators/Collaboration/List.vue";
import QuantitativeIndicators from "@/components/Indicators/Quantitative/List.vue";

@Component({
  components: {
    PageSection,
    QuantitativeIndicators,
    QualitativeIndicators,
    CollaborationIndicators,
  },
})
export default class GeneralIndicators extends Vue {
  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get titleSize() {
    return this.isMobile ? "xs" : "sm";
  }
}
