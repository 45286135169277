
import { Mixins, Component, Prop } from "vue-property-decorator";
import BarChart from "@/components/EChart/Bar.vue";
import { CHART_PRIMARY_COLOR } from "@/utils/Constants";
import { safeAsync } from "@/utils/AsyncUtil";
import IndicatorMixin, {
  IndicatorMixinTags,
} from "@/mixins/http/IndicatorMixin";
import { watcherStore } from "@/store/typed";
import StoreSubscriber from "@/utils/StoreSubscriber";
import Spinner from "@/components/Common/Spinner.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { INDICATOR_DESCRIPTIONS } from "../../../utils/Constants";

@Component({
  components: {
    BarChart,
    Spinner,
  },
})
export default class QualitativeIndicatorItem extends Mixins(IndicatorMixin) {
  @Prop({ required: true }) readonly category!: string;
  @Prop({ required: true }) readonly code!: string;
  @Prop({ required: true }) readonly label!: string;
  @Prop({ default: 90 }) readonly labelsWidth!: number;
  @Prop({ default: 11 }) readonly labelsFontSize!: number;

  private report: any = null;

  @RegisterHttp(IndicatorMixinTags.IndicatorQualitative)
  indicatorsRequest!: RequestData;

  get loading() {
    return this.indicatorsRequest?.loading;
  }

  get showChart() {
    return this.values?.labels.length && this.values?.series?.length;
  }

  get palette() {
    return [CHART_PRIMARY_COLOR];
  }

  mounted() {
    this.loadData();
    StoreSubscriber.subscribe("_setFilter", this.onFilterChanged);
  }

  get filter() {
    return watcherStore.filter;
  }

  onFilterChanged(value: any) {
    this.$nextTick(() => {
      this.loadData();
    });
  }

  get payload() {
    return {
      filter: {
        category: this.category,
        code: this.code,
        ...this.filter,
      },
    };
  }

  assignLabels(report: any) {
    const data: any[] = [];
    report.values.forEach((o: any) => {
      o.code = o.name;
      o.name = INDICATOR_DESCRIPTIONS[o.name]?.short;
      data.push(o);
    });
    report.values = data;
    return report;
  }

  async loadData() {
    const data = await watcherStore.getQualitativeData({
      code: this.code,
      category: this.category,
    });

    if (data) {
      this.report = this.assignLabels(data);
    }
  }

  get values() {
    const values = this.report?.values || [];

    return {
      labels: values.map((o: any) => o.name),
      series: [{ data: values.map((o: any) => o.value) }],
      codes: values.map((o: any) => o.code),
    };
  }
}
