
import { Vue, Component, Mixins, Ref, Prop } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import EventMixin, { EventMixinTags } from "@/mixins/http/admin/EventMixin";

@Component({
  components: {
    VModal,
  },
})
export default class EventContactModal extends Mixins(EventMixin) {
  @Prop({ required: true }) readonly eventId!: string;

  @Ref() readonly modal!: VModal;

  private message = "";

  @RegisterHttp(EventMixinTags.EventContactOwner)
  readonly contactOwnerRequest!: RequestData;

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  get loading() {
    return this.contactOwnerRequest?.loading;
  }

  private async sendEmail() {
    const [data, errors] = await safeAsync(
      this.contactOwner(this.eventId, this.message)
    );

    if (errors) {
      showErrorToast("Errore durante l'invio dell'email");
      return;
    }

    showToast("Email inviata con successo!");
    this.hide();
  }
}
