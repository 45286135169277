
import { Vue, Component, Prop } from "vue-property-decorator";
import QuantitativeIndicatorReport from "@/models/QuantitativeIndicatorReport";
import QuantitativeIndicatorItem from "@/components/Indicators/Quantitative/Item.vue";
import { INDICATOR_DESCRIPTIONS } from "@/utils/Constants";
@Component({
  components: {
    QuantitativeIndicatorItem,
  },
})
export default class QuantitativeIndicatorList extends Vue {
  get charts() {
    return [
      { category: "IG", code: "1", type: "money" },
      { category: "IG", code: "2", type: "time" },
      { category: "IG", code: "3", type: "quantity" },
    ];
  }

  get chartsFullData() {
    const data: any[] = [];
    this.charts.forEach((o: any) => {
      const key = `${o.category}-${o.code}`;
      o.label = INDICATOR_DESCRIPTIONS[key]?.long;
      data.push(o);
    });
    return data;
  }
}
