
import { Component, Ref, Mixins } from "vue-property-decorator";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { Initiative, Event } from "../../models";
import WithErrors from "@/directives/WithErrors";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import EventMixin, { EventMixinTags } from "@/mixins/http/admin/EventMixin";
import { EventPayload } from "../../models/Event";
import { safeAsync } from "@/utils/AsyncUtil";
import InitiativeSelect from "@/components/Initiative/Select.vue";
import { showErrorToast } from "@/utils/Toast";
import { currentUserStore, httpStore } from "../../store/typed";
import { showToast } from "@/utils/Toast";
import VFileUploader from "@/components/Common/VFileUploader.vue";

@Component({
  directives: {
    WithErrors,
  },
  components: {
    VModal,
    VFileUploader,
    InitiativeSelect,
  },
})
export default class CreateEventModal extends Mixins(EventMixin) {
  @Ref() readonly modal!: VModal;
  @RegisterHttp(EventMixinTags.EventCreate) httpRequest!: RequestData;
  @RegisterHttp(EventMixinTags.EventAddImage) uploadRequest!: RequestData;

  private type: string | null = null;
  private event: EventPayload = EventPayload.empty();
  private imageFile: any = null;
  private documentFiles: any[] = [];

  get currentUser() {
    return currentUserStore.currentUser;
  }

  get organizationId() {
    return this.currentUser?.organization?.id;
  }

  detachImage() {
    this.imageFile = null;
  }

  get isBreakingNews() {
    return this.type === "breaking_news";
  }

  get isEvent() {
    return this.type === "event";
  }

  get isWebinar() {
    return this.type === "webinar";
  }

  get title() {
    return "Crea un evento";
  }

  get httpTag() {
    return EventMixinTags.EventCreate;
  }

  get uploadTag() {
    return EventMixinTags.EventAddImage;
  }

  get loading() {
    return (
      httpStore.status[this.httpTag]?.loading ||
      httpStore.status[this.uploadTag]?.loading
    );
  }

  create() {
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  onCreated(initiative: Initiative) {
    this.$emit("created", initiative);
    this.hide();
  }

  reset() {
    this.event = EventPayload.empty();
  }

  async onUpload() {
    const [data, errors] = await safeAsync(this.createEvent(this.event));
    if (data) {
      this.uploadMedia(data);
    } else {
      showErrorToast("Si è verificato un errore");
    }
  }

  async uploadMedia(event: Event) {
    console.log(event);
    const formData = new FormData();
    if (this.imageFile?.file) {
      formData.append("image", this.imageFile.file);
    }

    const [data, errors] = await safeAsync(this.addMedia(event.id, formData));
    if (data) {
      this.onCreated(data);
      this.reset();
      showToast("Event creato!");
    } else {
      showErrorToast("Si è verificato un errore");
    }
  }
}
