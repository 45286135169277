
import { Vue, Component } from "vue-property-decorator";
import QualitativeIndicatorItem from "@/components/Indicators/Qualitative/Item.vue";
import { INDICATOR_DESCRIPTIONS } from "../../../utils/Constants";

@Component({
  components: {
    QualitativeIndicatorItem,
  },
})
export default class QualitativeIndicatorList extends Vue {
  get charts() {
    return [
      {
        category: "IG",
        code: "4",
        labelsWidth: 90,
        labelsFontSize: 11,
      },
      {
        category: "IG",
        code: "5",
        labelsWidth: 90,
        labelsFontSize: 11,
      },
      {
        category: "IG",
        code: "6",
        labelsWidth: 40,
        labelsFontSize: 9,
      },
    ];
  }

  get chartsFullData() {
    const data: any[] = [];
    this.charts.forEach((o: any) => {
      const key = `${o.category}-${o.code}`;
      o.label = INDICATOR_DESCRIPTIONS[key]?.long;
      data.push(o);
    });
    console.log("full data", data);
    return data;
  }
}
