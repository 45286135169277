
import { Vue, Component, Prop } from "vue-property-decorator";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GaugeChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import NumberFormatter from "@/utils/NumberFormatter";

use([
  CanvasRenderer,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

@Component({
  components: {
    VChart,
  },
})
export default class EChartGauge extends Vue {
  @Prop({ default: (): any => [] }) readonly palette!: string[];
  @Prop({ default: 0 }) readonly value!: number;
  @Prop({ default: 32 }) readonly valueFontSize!: number;
  @Prop({ default: "" }) readonly labelPrefix!: number;
  @Prop({ default: "" }) readonly labelSuffix!: number;
  @Prop({ default: 15 }) readonly ringThickness!: number;
  @Prop({ default: 100 }) readonly max!: number;
  @Prop({ default: 180 }) readonly width!: number;
  @Prop({ default: 180 }) readonly height!: number;
  @Prop({ default: false }) readonly textAutoResize!: boolean;

  get style() {
    return { width: `${this.width}px`, height: `${this.height}px` };
  }

  get fontSize() {
    if (this.textAutoResize) {
      const string =
        this.labelPrefix + this.value.toString() + this.labelSuffix;
      return this.valueFontSize / (string.length / 4);
    }

    return this.valueFontSize;
  }

  get option() {
    return {
      series: [
        {
          max: this.max,
          color: this.palette[0],
          type: "gauge",
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: false,
            clip: false,
            itemStyle: {
              borderWidth: 1,
            },
          },
          axisLine: {
            lineStyle: {
              width: this.ringThickness,
            },
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: [
            {
              value: this.value,
              detail: {
                valueAnimation: true,
                offsetCenter: ["0%", "0%"],
              },
            },
          ],

          detail: {
            width: 100,
            height: 100,
            fontSize: this.fontSize,
            fontFamily: "Titillium Web",
            color: "black",
            borderColor: "auto",
            formatter: `${this.labelPrefix}${this.formattedValue}${this.labelSuffix}`,
          },
        },
      ],
    };
  }

  get formattedValue() {
    return this.value % 1 === 0
      ? parseInt(this.value.toString()).toString().replace(".", ",")
      : this.value.toString().replace(".", ",");
  }
}
