
import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import PieChart from "@/components/EChart/Pie.vue";
import { CHART_PALETTE } from "@/utils/Constants";
import { PieChartEntry } from "@/models/echart/types";
import IndicatorsMultiselect from "@/components/Indicators/Multiselect.vue";
import PageSection from "@/components/Common/PageSection.vue";
import IndicatorMixin, {
  IndicatorMixinTags,
} from "@/mixins/http/IndicatorMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";

import IndicatorsSelect from "@/components/Indicators/Select.vue";

@Component({
  components: {
    PieChart,
    IndicatorsMultiselect,
    PageSection,
    IndicatorsSelect,
  },
})
export default class IndicatorDistributionSection extends Mixins(
  IndicatorMixin
) {
  private regionData: PieChartEntry | null = null;
  private organizationTypeData: PieChartEntry | null = null;

  private selectedIndicator = "";
  private selectedCategory = "IG";

  @RegisterHttp(IndicatorMixinTags.IndicatorDistribution)
  distributionRequest!: RequestData;

  mounted() {
    this.loadData();
  }

  get palette() {
    return CHART_PALETTE;
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get titleSize() {
    return this.isMobile ? "xs" : "sm";
  }

  get params() {
    return { category: this.selectedCategory };
  }

  get loading() {
    return this.distributionRequest?.loading;
  }

  onSelectChanged(value: string) {
    this.selectedIndicator = value;
    this.loadData();
  }

  async loadData() {
    const [data, errors] = await safeAsync(
      this.getDistribution({ codes: [this.selectedIndicator] })
    );
    this.regionData = data.organizationRegions;
    this.organizationTypeData = data.organizationCategories;
  }
}
