
import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import CollaborationIndicatorReport from "@/models/CollaborationIndicatorReport";
import CollaborationIndicatorItem from "@/components/Indicators/Collaboration/Item.vue";
import IndicatorMixin, {
  IndicatorMixinTags,
} from "@/mixins/http/IndicatorMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { watcherStore } from "@/store/typed";
import StoreSubscriber from "@/utils/StoreSubscriber";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import Spinner from "@/components/Common/Spinner.vue";

@Component({
  components: {
    CollaborationIndicatorItem,
    Spinner,
  },
})
export default class CollaborationIndicatorList extends Mixins(IndicatorMixin) {
  private reports: CollaborationIndicatorReport[] = [];

  @RegisterHttp(IndicatorMixinTags.CollaborationReuse)
  indicatorsRequest!: RequestData;

  created() {
    this.loadIndicators();
    StoreSubscriber.subscribe("_setFilter", this.onFilterChanged);
  }

  get loading() {
    return this.indicatorsRequest?.loading;
  }

  get filter() {
    return watcherStore.filter;
  }

  get payload() {
    return {
      filter: {
        ...this.filter,
      },
    };
  }

  onFilterChanged(value: any) {
    this.$nextTick(() => {
      this.loadIndicators();
    });
  }

  private async loadIndicators() {
    const data = await watcherStore.getCollaborationData();
    if (data) {
      this.reports = data;
    }
  }
}
