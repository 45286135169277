
import { Vue, Component, Ref } from "vue-property-decorator";
import EventFeed from "@/components/Event/List.vue";
import PageSection from "@/components/Common/PageSection.vue";
import CreateEventModal from "@/components/Event/CreateModal.vue";
import { currentUserStore } from "../../store/typed";

@Component({
  components: {
    EventFeed,
    PageSection,
    CreateEventModal,
  },
})
export default class Events extends Vue {
  @Ref() readonly createEventModal!: CreateEventModal;
  @Ref() readonly eventFeed!: EventFeed;

  onCreated() {
    (this.eventFeed as any).reload();
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  get isAdmin() {
    return this.currentUser.isAdmin;
  }

  onAdd() {
    (this.createEventModal as any).create();
  }
}
