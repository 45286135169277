
import StoreSubscriber from "@/utils/StoreSubscriber";
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import VAccordion from "@/components/Common/VAccordion.vue";
import MapChart from "@/components/EChart/Map.vue";
import GaugeChart from "@/components/EChart/Gauge.vue";
import BarProgress from "@/components/Indicators/Quantitative/Progress.vue";
import { CHART_PALETTE, CHART_PRIMARY_COLOR } from "@/utils/Constants";
import SavingBadge from "@/components/Indicators/Quantitative/RegionSavingsBadge.vue";
import IndicatorMixin, {
  IndicatorMixinTags,
} from "@/mixins/http/IndicatorMixin";
import { watcherStore } from "@/store/typed";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import Spinner from "@/components/Common/Spinner.vue";
import NumberFormatter from "@/utils/NumberFormatter";
import { IndicatorDeltaPositivity } from "@/models/QuantitativeIndicatorReport";
import { printStore } from "../../../store/typed";
import { mapNameFromRealm } from "@/utils/Realms";

@Component({
  components: {
    VAccordion,
    MapChart,
    GaugeChart,
    BarProgress,
    SavingBadge,
    Spinner,
  },
})
export default class QuantitativeIndicatorItem extends Mixins(IndicatorMixin) {
  @Prop({ required: true }) readonly code!: string;
  @Prop({ required: true }) readonly category!: string;
  @Prop({ required: true }) readonly label!: string;
  @Prop({ required: true }) readonly type!: string;
  @Ref() readonly mapChart: any;

  private report: any = null;
  private selectedRegion: string | null = null;
  private showMap = true;

  @RegisterHttp(IndicatorMixinTags.IndicatorQuantitative)
  readonly indicatorRequest!: RequestData;

  onRegionSelected(entry: { name: string; value: string }) {
    this.selectedRegion = entry.name;
  }

  get printing() {
    return printStore.printing;
  }

  unselectAll() {
    this.selectedRegion = null;
    this.showMap = false;
    this.$nextTick(() => (this.showMap = true));
  }

  mounted() {
    this.loadData();
    StoreSubscriber.subscribe("_setFilter", this.onFilterChanged);
  }

  get loading() {
    return this.indicatorRequest?.loading;
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get filter() {
    return watcherStore.filter;
  }

  onFilterChanged(value: any) {
    this.$nextTick(() => {
      this.loadData();
    });
  }

  get payload() {
    return {
      filter: {
        code: this.code,
        category: this.category,
        ...this.filter,
      },
    };
  }

  async loadData() {
    const data = await watcherStore.getQuantitativeData({
      code: this.code,
      category: this.category,
    });

    if (data) {
      this.report = data;
    }
  }

  private formattedExpense(value: number) {
    if (this.type === "quantity") {
      return value;
    }

    const formattedMoney = NumberFormatter.moneyFormatter(value);

    return this.type === "time"
      ? `${value}gg`
      : `${NumberFormatter.engToItalian(formattedMoney.value) || 0}${
          formattedMoney.measurementUnit
        } €`;
  }

  get formattedAbsoluteDelta() {
    return NumberFormatter.engToItalian(parseFloat(this.report.absoluteDelta));
  }

  get gaugePalette() {
    return [CHART_PRIMARY_COLOR];
  }
  get palette() {
    return CHART_PALETTE;
  }

  get previousExpensesProgress() {
    if (!this.expensesAfter && !this.expensesBefore) {
      return 0;
    }
    return this.expensesAfter > this.expensesBefore
      ? (this.expensesBefore / this.expensesAfter) * 100
      : 100;
  }

  get currentExpensesProgress() {
    if (!this.expensesAfter && !this.expensesBefore) {
      return 0;
    }
    return this.expensesAfter > this.expensesBefore
      ? 100
      : (this.expensesAfter / this.expensesBefore) * 100;
  }

  get totalPreviousExpensesProgress() {
    const expensesAfter = this.report.totalExpenseAfter;
    const expensesBefore = this.report.totalExpenseBefore;
    if (!expensesAfter && !expensesBefore) {
      return 0;
    }
    return expensesAfter > expensesBefore
      ? (expensesBefore / expensesAfter) * 100
      : 100;
  }

  get totalCurrentExpensesProgress() {
    const expensesAfter = this.report.totalExpenseAfter;
    const expensesBefore = this.report.totalExpenseBefore;
    if (!expensesAfter && !expensesBefore) {
      return 0;
    }
    return expensesAfter > expensesBefore
      ? 100
      : (expensesAfter / expensesBefore) * 100;
  }

  get savings(): number {
    return parseFloat(this.report.absoluteDelta);
  }

  get savingPositivity() {
    const foundInd = IndicatorDeltaPositivity.find(
      (el) => el.code === `${this.category}${this.code}`
    );
    return foundInd.positity;
  }

  get subHeaderBarsValues() {
    const beforeValue = NumberFormatter.moneyFormatter(
      this.report.totalExpenseBefore
    );
    const afterValue = NumberFormatter.moneyFormatter(
      this.report.totalExpenseAfter
    );

    const label = this.type === "time" ? "gg" : beforeValue.measurementUnit;

    return [
      {
        name: "Ante CE " + (this.type !== "quantity" ? `(${label})` : ""),
        progress: this.totalPreviousExpensesProgress,
        value: this.report.totalExpenseBefore,
      },
      {
        name: "Post CE " + (this.type !== "quantity" ? `(${label})` : ""),
        progress: this.totalCurrentExpensesProgress,
        value: this.report.totalExpenseAfter,
      },
    ];
  }

  get barsValues() {
    const beforeValue = NumberFormatter.moneyFormatter(
      this.report.totalExpenseBefore
    );
    const afterValue = NumberFormatter.moneyFormatter(
      this.report.totalExpenseAfter
    );

    const label = this.type === "time" ? "gg" : beforeValue.measurementUnit;

    return [
      {
        name: "Ante CE " + (this.type !== "quantity" ? `(${label})` : ""),
        progress: this.previousExpensesProgress,
        value: this.expensesBefore,
      },
      {
        name: "Post CE " + (this.type !== "quantity" ? `(${label})` : ""),
        progress: this.currentExpensesProgress,
        value: this.expensesAfter,
      },
    ];
  }

  get gaugeValues() {
    const formattedTotal = NumberFormatter.moneyFormatter(this.budgetTotal);
    const formattedSpent = NumberFormatter.moneyFormatter(this.budgetSpent);
    const formattedSaved = NumberFormatter.moneyFormatter(this.budgetSaved);

    return [
      {
        name: "Attivati",
        value: this.regionProjectCount,
        max: this.regionProjectCount || 100,
      },
      {
        name: "Completati",
        value: this.regionCompletedProjectCount,
        max: this.regionProjectCount || 100,
      },
      {
        name: "In corso",
        value: this.regionInProgressProjectCount,
        max: this.regionProjectCount || 100,
      },
      {
        name: this.budgetTotal
          ? `Impegnato (${formattedTotal.measurementUnit})`
          : "Impegnato",
        value: formattedTotal.value,
        max: this.budgetTotal || 100,
      },
      {
        name: this.budgetSpent
          ? `Speso (${formattedSpent.measurementUnit})`
          : "Speso",
        value: formattedSpent.value,
        max: this.budgetTotal || 100,
      },
      {
        name: this.budgetSaved
          ? `Residuo (${formattedSaved.measurementUnit})`
          : "Residuo",
        value: formattedSaved.value,
        max: this.budgetTotal || 100,
      },
    ];
  }

  get deltaClass() {
    const foundInd = IndicatorDeltaPositivity.find(
      (el) => el.code === `${this.category}${this.code}`
    );
    switch (foundInd.positity) {
      case "positive":
        return this.report.delta > 0 ? "success" : "warning";
      case "negative":
        return this.report.delta < 0 ? "success" : "warning";
    }

    return "";
  }

  get regionProjectCount() {
    return this.selectedRegion
      ? this.report.allProjectsByRegion(this.selectedRegion)
      : this.report.totalProjects;
  }

  get regionCompletedProjectCount() {
    return this.selectedRegion
      ? this.report.completedProjectsByRegion(this.selectedRegion)
      : this.report.completedProjects;
  }

  get regionInProgressProjectCount() {
    return this.selectedRegion
      ? this.report.inProgressProjectsByRegion(this.selectedRegion)
      : this.report.inProgressProjects;
  }

  get budgetTotal() {
    return this.selectedRegion
      ? this.report.budgetTotalByRegion(this.selectedRegion)
      : this.report.totalBudget;
  }

  get expensesBefore() {
    return this.selectedRegion
      ? this.report.expensesBefore(this.selectedRegion)
      : this.report.totalExpenseBefore;
  }

  get expensesAfter() {
    return this.selectedRegion
      ? this.report.expensesAfter(this.selectedRegion)
      : this.report.totalExpenseAfter;
  }

  get budgetSpent() {
    return this.selectedRegion
      ? this.report.budgetSpentByRegion(this.selectedRegion)
      : this.report.spentBudget;
  }
  get budgetSaved() {
    return this.selectedRegion
      ? this.report.budgetSavedByRegion(this.selectedRegion)
      : this.report.savedBudget;
  }

  get values() {
    return this.report.toMapValues;
  }

  get mapName() {
    return mapNameFromRealm();
  }
}
