
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { BarChartEntry } from "@/models/echart/types";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DatasetComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import { INDICATOR_DESCRIPTIONS } from "@/utils/Constants";
use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent
]);

import DescriptionModal from "@/components/Common/DescriptionModal.vue";
import Throttler from "@/utils/Throttler";

@Component({
  components: {
    VChart,
    DescriptionModal,
  },
})
export default class EChartBar extends Vue {
  @Prop() readonly title!: string;
  @Prop({ default: "left" }) readonly titleAlignment!: string;
  @Prop({ default: true }) readonly showLegend!: boolean;
  @Prop({ default: true }) readonly vertical!: boolean;
  @Prop({ default: 500 }) readonly height!: boolean;
  @Prop({ default: 90 }) readonly labelsWidth!: number;
  @Prop({ default: 11 }) readonly labelFontSize!: number;
  @Prop({ default: false }) readonly grouped!: boolean;

  @Prop({ default: (): any => [] }) readonly palette!: string[];

  @Prop({ default: "truncate" }) readonly labelOverflow!:
    | "truncate"
    | "break"
    | "breakAll";

  @Prop({
    default: (): any => {
      return { labels: [], values: [], codes: [] };
    },
  })
  readonly values!: BarChartEntry;

  @Ref() readonly descriptionModal!: DescriptionModal;

  private throttler = new Throttler(100);

  private openDescriptionModal(value: any) {
    const indCode = this.values.codes[value.dataIndex];
    const indDescription = INDICATOR_DESCRIPTIONS[indCode].long;
    const title = indCode === value.name ? indCode : `${indCode} - ${value.name}`;

    this.throttler.run(() => {
      this.descriptionModal.show(indDescription, title);
    });
  }

  get titleConfig(): any {
    return { text: this.title, left: this.titleAlignment };
  }

  get labels(): string[] {
    return this.values?.labels;
  }

  get chartStyle(): any {
    return { height: `${this.height}px` };
  }

  get legend(): any {
    return this.showLegend ? {} : null;
  }

  get option(): any {
    return this.plainOptions;
  }

  get grid(): any {
    return {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    }
  }

  get plainOptions(): any {
    return {
      title: this.titleConfig,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: this.legend,
      grid: this.grid,
      xAxis: this.vertical ? this.labelData : this.valueData,
      yAxis: this.vertical ? this.valueData : this.labelData,
      series: [...this.series || []],
    }
  }

  get labelData(): any {
    return {
      type: "category",
      data: this.labels,
      axisLabel: {
        interval: 0,
        fontSize: this.labelFontSize,
        margin: 10,
        width: this.labelsWidth,
        overflow: this.labelOverflow,
      },
    };
  }

  get valueData(): any {
    return {
      type: "value",
      boundaryGap: [0, 0.01],
    };
  }

  get series(): any[] {
    if (!this.values.series) {
      return [];
    }

    return this.values.series.map((o: any) => {
      const data = o.data.map((data: any, dataIndex: number) => {
        const color =
          this.palette.length === this.values.labels.length
            ? this.palette[dataIndex]
            : this.palette[0];
        return {
          value: data,
          itemStyle: { color: color },
        };
      });

      return {
        type: "bar",
        barMaxWidth: 80,
        barCategoryGap: "20%",
        label: this.labelConfig,
        name: o.name,
        data: data,
      };
    });
  }

  get labelConfig(): any {
    return {
      show: true,
      position: "inside",
      color: "white",
    };
  }
}
